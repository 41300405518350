import { setValues } from "framer-motion/types/render/utils/setters"
import { useState, useEffect, useContext } from "react"
import tw from "twin.macro"
import { ContactContext } from "../../../contexts/Contact"
import { LanguageContext } from "../../../contexts/Language"
import { useTealiumContext } from "../../../contexts/Tealium"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { TPCOEmailOptInContext } from "../../../contexts/TPCOEmailOptIn/context"
import {
  updateField,
  setActiveView,
  setPreviousView,
} from "../../../contexts/TPCOEmailOptIn/actions"
import { ViewProps } from "../ContactDealer/ContactDealer.d"
import FormPage from "../ContactDealer/FormPage"
import InputField from "../ContactDealer/InputField"
import { tealiumNavClick } from "../ContactDealer/TealiumHelpers"

const NameView: React.FC<ViewProps> = ({ active }) => {
  const [showVal, setShowVal] = useState(false)
  const [{ fullName, activeView }, dispatch] = useContext(TPCOEmailOptInContext)
  const { _ } = useContext(LanguageContext)

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { contactDealerData } = useTealiumContext()

  useEffect(() => {
    if (activeView == "NameView") {
      trackTealEvent({
        tealium_event: "contact_dealer",
        contact_question: "What's your name?",
        ...contactDealerData?.fromInventory,
        ...contactDealerData?.dealerData,
        ...contactDealerData?.allocatedDealer,
      })
    }
  }, [contactDealerData, active])

  const handleClickNext = () => {
    tealiumNavClick(
      "What's your name?",
      "Next",
      trackTealEvent,
      contactDealerData
    )

    const names = fullName?.trim()?.split(" ")
    if (names?.length === 2) {
      dispatch(updateField("firstName", names[0]))
      dispatch(updateField("lastName", names[1]))
      dispatch(setPreviousView("NameView"))
      dispatch("NameView")
      dispatch(setActiveView("MethodOfContactView"))
    } else if (names?.length > 2) {
      const names = fullName?.split(" ")
      const initialFirstName = names?.shift()
      dispatch(updateField("firstName", initialFirstName))
      const initialLastNameValue = names?.join(" ")
      dispatch(updateField("lastName", initialLastNameValue))
      dispatch(setActiveView("NameValidationView"))
    } else {
      setShowVal(true)
      const nameInputEl = document.getElementById("fullName")
      nameInputEl?.focus()
    }
  }

  useEffect(() => {
    if (showVal) {
      setShowVal(false)
    }
  }, [fullName])

  if (!active) return null

  return (
    <FormPage
      active={active}
      nextOnClick={handleClickNext}
      heading={_("What's your name?")}
      showFormHeading={false}
    >
      <InputField
        active={active}
        showVal={showVal}
        fieldName="fullName"
        label={_("First & Last Name")}
        validationMessage={_("We need your first and last name.")}
        value={fullName}
        customOnChange={(value: string) =>
          dispatch(updateField("fullName", value))
        }
        type="text"
        css={[tw`max-w-[15rem]`]}
        heading="What's your name?"
      />
    </FormPage>
  )
}

export default NameView
