import React, { useContext } from "react"
import { TPCOEmailOptInContext } from "../../../contexts/TPCOEmailOptIn/context"
import tw from "twin.macro"
import { Modal } from "../../atoms/Modal"
import {
  closeTPCOEmailOptInModal,
  clearState,
} from "../../../contexts/TPCOEmailOptIn/actions"
import NameView from "./NameView"
import NameValidationView from "./NameValidationView"
import MethodOfContactView from "./MethodOfContactView"
import { LanguageContext } from "../../../contexts/Language"
import SummaryView from "./SummaryView"
import { TPCOFormProps } from "./TPCOForm.d"

const TPCOForm: React.FC<TPCOFormProps> = ({ modalIsOpen, setModalIsOpen }) => {
  const [{ isModalOpen, activeView }, dispatch] = useContext(
    TPCOEmailOptInContext
  )
  const { language, _ } = useContext(LanguageContext)

  const resetForm = () => {
    dispatch(clearState())
    dispatch(closeTPCOEmailOptInModal())
  }

  return (
    <Modal
      css={[
        tw`w-full h-full max-h-full bg-gray-50`,
        tw`lg:(max-w-[50rem] max-h-[475px])`,
        "z-index: 9999;",
      ]}
      open={isModalOpen}
      onClose={resetForm}
    >
      <NameView active={activeView === "NameView"} />
      <NameValidationView active={activeView === "NameValidationView"} />
      <MethodOfContactView active={activeView === "MethodOfContactView"} />
      <SummaryView active={activeView === "SummaryView"} />
    </Modal>
  )
}

export default TPCOForm
