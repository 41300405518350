import { css } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { Carousel, CarouselItem } from "../../atoms/Carousel"
import { CarouselChildFunction } from "../../atoms/Carousel/Carousel.d"
import { Link } from "../../atoms/Link"
import { PartsProps } from "./Parts.d"
import { Image } from "../../atoms/Image"
import Icon from "../../atoms/Icon"
import { ButtonLink } from "../../atoms/Button"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { LanguageContext } from "../../../contexts/Language"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { parseDisclaimerBlocks } from "../../../helpers"
import { DisclaimersContext } from "../../../contexts/Disclaimers"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const Parts: React.FC<PartsProps> = ({ tpcoParts, partsTitle }) => {
  const active = css`
    ${tw`font-semibold`}
    &:after {
      transform: scaleX(1);
    }
  `

  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  const { _ } = useContext(LanguageContext)
  const [state, modalDispatch] = useContext(DisclaimersContext)
  return (
    <section
      aria-label="Toyota Parts Category Section"
      css={[tw`bg-gradient-to-t from-gray-50 w-full py-12 md:(py-28)`]}
    >
      {partsTitle && (
        <h2
          css={[
            tw`text-xl text-center font-light mx-auto px-6 mb-6 md:(max-w-xl text-4xl)`,
            "letter-spacing: 8.72px",
          ]}
        >
          {parseDisclaimerBlocks(partsTitle, selection =>
            modalDispatch(toggleDisclaimersModal(selection))
          )}
        </h2>
      )}

      <div
        css={[
          tw`flex flex-wrap justify-center items-center overflow-hidden max-w-desktop mx-auto px-6`,
          tw`lg:(gap-4 px-0)`,
        ]}
      >
        {tpcoParts.map((part: any, index: number) => (
          <Link
            className="group"
            onClick={() => {
              // NO LONGER USING CAROUSEL SHOULD THIS BE SOME OTHER EVENT CHECK?
              // trackTealEvent({
              //   tealium_event: "carousel_click",
              //   carousel_action: part.name,
              // })
            }}
            to={part?.url}
            target="_blank"
            aria-label="Link opens new tab"
            css={[
              tw`relative py-0 rounded overflow-hidden cursor-pointer `,
              tw`md:(w-[275px] h-[160px])`,
            ]}
          >
            <Image
              imageData={part.image.image}
              transparentPreview
              hideAlt
              css={[
                tw`ease-in-out duration-300 object-cover scale-100 group-hover:(scale-110)`,
              ]}
            />
            <span
              css={[
                tw`text-white font-semibold absolute bottom-8 z-10 block text-center w-full`,
                tw`group-hover:(underline)`,
              ]}
            >
              {part.name}
            </span>
          </Link>
        ))}
      </div>
    </section>
  )
}

export default Parts
