import { useState, useEffect, useContext } from "react"
import tw from "twin.macro"
import { ContactContext } from "../../../contexts/Contact"
import {
  setActiveView,
  updateField,
} from "../../../contexts/TPCOEmailOptIn/actions"
import { LanguageContext } from "../../../contexts/Language"
import { useTealiumContext } from "../../../contexts/Tealium"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { TPCOEmailOptInContext } from "../../../contexts/TPCOEmailOptIn/context"
import { setPreviousView } from "../../../contexts/TPCOEmailOptIn/actions"
import { ViewProps } from "../ContactDealer/ContactDealer.d"
import FormPage from "../ContactDealer/FormPage"
import InputField from "../ContactDealer/InputField"
import { tealiumNavClick } from "../ContactDealer/TealiumHelpers"

const NameValidationView: React.FC<ViewProps> = ({ active }) => {
  const [{ firstName, lastName }, dispatch] = useContext(TPCOEmailOptInContext)
  const { _ } = useContext(LanguageContext)
  const [showFirstNameValidation, setShowFirstNameValidation] = useState(false)
  const [showLastNameValidation, setShowLastNameValidation] = useState(false)

  useEffect(() => {
    firstName && setShowFirstNameValidation(false)
    lastName && setShowLastNameValidation(false)
  }, [firstName, lastName])

  const handleClickNext = () => {
    tealiumNavClick(
      "Did we get this right?",
      "Next",
      trackTealEvent,
      contactDealerData
    )
    if (firstName && lastName) {
      dispatch(setPreviousView("NameValidationView"))
      dispatch(setActiveView("MethodOfContactView"))
    } else if (!firstName) {
      setShowFirstNameValidation(true)
      !lastName && setShowLastNameValidation(true)
      const firstNameEl = document.getElementById("FirstName")
      firstNameEl.focus()
    } else {
      setShowLastNameValidation(true)
      const lastNameEl = document.getElementById("LastName")
      lastNameEl.focus()
    }
  }
  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { contactDealerData } = useTealiumContext()

  if (!active) return null

  return (
    <FormPage
      active={active}
      prevOnClick={() => {
        dispatch(setActiveView("NameView"))
        tealiumNavClick(
          "Did we get this right?",
          "Back",
          trackTealEvent,
          contactDealerData
        )
      }}
      nextOnClick={handleClickNext}
      heading={_("Did we get this right?")}
      showFormHeading={false}
    >
      <div css={tw`grid grid-cols-2 gap-6`}>
        <InputField
          active={active}
          type="text"
          fieldName="FirstName"
          label={_("First Name")}
          validationMessage={_("We need your first name.")}
          showVal={showFirstNameValidation}
          value={firstName}
          customOnChange={value => dispatch(updateField("firstName", value))}
          heading="Did we get this right?"
        />
        <InputField
          active={active}
          type="text"
          fieldName="LastName"
          label={_("Last Name")}
          validationMessage={_("We need your last name.")}
          showVal={showLastNameValidation}
          value={lastName}
          customOnChange={value => dispatch(updateField("lastName", value))}
          heading="Did we get this right?"
        />
      </div>
    </FormPage>
  )
}

export default NameValidationView
