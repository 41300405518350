import { useState, useEffect, useContext } from "react"
import tw from "twin.macro"
import { Radio } from "../../atoms/Radio"
import {
  setActiveView,
  updateField,
} from "../../../contexts/TPCOEmailOptIn/actions"
import { LanguageContext } from "../../../contexts/Language"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"
import { TPCOEmailOptInContext } from "../../../contexts/TPCOEmailOptIn/context"
import { ViewProps } from "../ContactDealer/ContactDealer.d"
import FormPage from "../ContactDealer/FormPage"
import InputField from "../ContactDealer/InputField"
import { tealiumNavClick } from "../ContactDealer/TealiumHelpers"

const MethodOfContactView: React.FC<ViewProps> = ({ active }) => {
  const [{ email, previousView }, dispatch] = useContext(TPCOEmailOptInContext)
  const { _ } = useContext(LanguageContext)

  const emailValidation = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )

  const inputDetails = {
    label: _("Email"),
    value: "email",
    type: "Email Address",
    input: "email",
    regEx: new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ),
    validationMessage: _("Please enter a valid email address."),
  }

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { contactDealerData } = useTealiumContext()

  const [showVal, setShowVal] = useState(false)

  const handleNextClick = () => {
    const isValid = emailValidation.test(email?.toString())
    tealiumNavClick(
      "How should we contact you?",
      "Next",
      trackTealEvent,
      contactDealerData
    )

    if (isValid) {
      dispatch(setActiveView("SummaryView"))
    } else {
      setShowVal(true)
      const contactEl = document.getElementById("contact")
      contactEl.focus()
    }
  }

  useEffect(() => {
    if (showVal) {
      setShowVal(false)
    }
  }, [email])

  if (!active) return null

  return (
    <FormPage
      active={active}
      prevOnClick={() => {
        tealiumNavClick(
          "How should we contact you?",
          "Back",
          trackTealEvent,
          contactDealerData
        )
        previousView === "NameValidationView"
          ? dispatch(setActiveView("NameValidationView"))
          : dispatch(setActiveView("NameView"))
      }}
      nextOnClick={handleNextClick}
      heading={_("How should we contact you?")}
      showFormHeading={false}
    >
      <InputField
        active={active}
        value={email}
        type="email"
        fieldName="contact"
        label={_("Email Address")}
        validationMessage={_("Please enter a valid email address.")}
        customOnChange={value => dispatch(updateField("email", value))}
        showVal={showVal}
        css={[tw`max-w-[20rem]`]}
        heading="How should we contact you?"
      />
    </FormPage>
  )
}

export default MethodOfContactView
