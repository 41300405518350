import { ACTION_TYPES } from "./actions"
import { defaultState } from "./context"

const tpcoEmailOptInReducer = (
  state: { isModalOpen: boolean },
  action: { type: ACTION_TYPES; payload?: any }
) => {
  switch (action.type) {
    case ACTION_TYPES.SET_MODAL_OPEN:
      return { ...state, isModalOpen: action.payload }
    case ACTION_TYPES.SET_ACTIVE_VIEW:
      return { ...state, activeView: action.payload }
    case ACTION_TYPES.UPDATE_FIELD:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      }
    case ACTION_TYPES.SET_PREVIOUS_VIEW:
      return {
        ...state,
        previousView: action.payload,
      }
    case ACTION_TYPES.CLEAR_STATE:
      return {
        ...defaultState,
      }
    default:
      return state
  }
}

export default tpcoEmailOptInReducer
