import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { GenuinePartsProps } from "./Parts.d"
import { Image } from "../../atoms/Image"
import { ButtonLink } from "../../atoms/Button"
import { Toyota } from "../../atoms/Logo"
import { Find } from "../../molecules/Find"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { getCTALinkValue, parseDisclaimerBlocks } from "../../../helpers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const GenuineParts: React.FC<GenuinePartsProps> = ({
  genuinePartsBackground,
  genuinePartsTitle,
  genuinePartsDescription,
  genuinePartsCTA,
}) => {
  let ctaTarget = "_self"

  const [{ disclaimers }, dispatch] = useContext(DisclaimersContext)

  if (genuinePartsCTA?.linkType === "external") {
    ctaTarget = "_blank"
  }

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  return (
    <section
      aria-label="Genuine Parts Hero Section"
      css={[
        tw`flex flex-col h-auto bg-[#ccc] relative`,
        tw`sm:(items-center flex-row h-[680px])`,
      ]}
    >
      {genuinePartsBackground && (
        <Image
          imageData={genuinePartsBackground}
          css={[
            tw`absolute top-0 w-full h-auto object-cover`,
            "z-index: 0;",
            tw`sm:(h-[680px] absolute top-0 object-cover)`,
          ]}
        />
      )}
      <div
        css={[
          tw`ml-0 w-full z-20 bg-white p-10`,
          tw`sm:(w-auto mx-auto mb-32 rounded-2xl bg-opacity-90)`,
          tw`lg:(ml-20)`,
        ]}
      >
        <h1
          css={[
            tw`text-xl font-semibold mb-4 flex items-center`,
            tw`sm:(text-4xl mb-4)`,
          ]}
        >
          <Toyota showText={false} css={[tw`mr-3`]} />
          {parseDisclaimerBlocks(genuinePartsTitle, code =>
            dispatch(toggleDisclaimersModal(code))
          )}
        </h1>
        <h4
          css={[
            tw`text-base tracking-widest font-light leading-normal mb-6`,
            tw`sm:(text-lg mb-6 max-w-[600px])`,
          ]}
        >
          {parseDisclaimerBlocks(genuinePartsDescription, code =>
            dispatch(toggleDisclaimersModal(code))
          )}
        </h4>
        <ButtonLink
          to={getCTALinkValue(genuinePartsCTA)}
          target={ctaTarget}
          css={[tw`bg-none`]}
          onClick={() =>
            trackTealEvent({
              tealium_event: "lander_anchor",
              anchor_button_text: genuinePartsCTA?.title,
            })
          }
          analytics-id="select dealer:hero:"
          {...{ [genuinePartsCTA?.buttonType]: true }}
        >
          {genuinePartsCTA?.title}
        </ButtonLink>
      </div>
      {/* <div
        css={[
          tw`border-t border-b bg-white relative -ml-12 overflow-hidden bottom-auto right-auto`,
          tw`md:(absolute -bottom-20 right-40 z-10 border-0 bg-transparent)`,
        ]}
      >
        <Find type={"scheduleService"} css={[tw`md:(shadow-4)`]} />
      </div> */}
    </section>
  )
}

export default GenuineParts
