import tw from "twin.macro"
import { Button, ButtonLink } from "../../atoms/Button"
import React, { useContext, useRef, useState } from "react"
import {
  clearState,
  closeTPCOEmailOptInModal,
  setActiveView,
  updateField,
} from "../../../contexts/TPCOEmailOptIn/actions"
import ReCAPTCHA from "react-google-recaptcha"
import { LanguageContext } from "../../../contexts/Language"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"
import { TPCOEmailOptInContext } from "../../../contexts/TPCOEmailOptIn/context"
import { ViewProps } from "../ContactDealer/ContactDealer.d"
import FormPage from "../ContactDealer/FormPage"
import { tealiumSubmit, tealiumNavClick } from "../ContactDealer/TealiumHelpers"
import ValidationPopover from "../ContactDealer/ValidationPopover"
import { SubmitFormStatus } from "./TPCOForm.d"

const SummaryView: React.FC<ViewProps> = ({ active }) => {
  const [{ gRecaptchaResponse, firstName, lastName, email }, dispatch] =
    useContext(TPCOEmailOptInContext)
  const { _ } = useContext(LanguageContext)
  const recaptchaRef = useRef(null)
  const [isValid, setIsValid] = useState(false)
  const [showValidatioMessage, setShowValidationMessage] = useState(false)
  const [submitStatus, setSubmitStatus] =
    useState<SubmitFormStatus>("not submitted")
  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { contactDealerData } = useTealiumContext()
  const firstLoad = useRef(true)

  const updateRecaptcha = () => {
    const recaptchaValue = recaptchaRef.current?.getValue()
    dispatch(updateField("gRecaptchaResponse", recaptchaValue))
    setIsValid(true)
    setShowValidationMessage(false)
  }

  const submitForm = async () => {
    setSubmitStatus("submitting")
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-api-key": process.env.GATSBY_API_KEY,
      },
      body: JSON.stringify({
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim(),
        gRecaptchaResponse,
      }),
    }
    try {
      const res = await fetch(
        `${process.env.GATSBY_API_URL}/tpco/emails/opt-in`,
        config
      )
      if (res.status === 200) {
        setSubmitStatus("success")
      } else {
        setSubmitStatus("error")
      }
    } catch (error) {
      console.log(error.message)
      setSubmitStatus("error")
    }
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    tealiumSubmit(
      "contact_dealer_submission",
      "Review & Submit Your Request",
      email,
      trackTealEvent,
      contactDealerData
    )
    if (!gRecaptchaResponse) {
      setShowValidationMessage(true)
      setIsValid(false)
    } else {
      setIsValid(true)
      submitForm()
    }
  }

  if (submitStatus === "success" || submitStatus === "error") {
    // Explicitly pass contactDealerData (required for event in SDR) and prevent event from firing on resize
    if (firstLoad.current) {
      tealiumSubmit(
        "thank_you_page",
        "Review & Submit Your Request",
        email,
        trackTealEvent,
        contactDealerData,
        contactDealerData?.dealerData,
        { contact_form_field: "TPCO" }
      )
      firstLoad.current = false
    }
    return (
      <FormPage
        active={active}
        nextButtonText={_("Close")}
        showFormHeading={false}
        nextOnClick={() => {
          dispatch(clearState())
          dispatch(closeTPCOEmailOptInModal())
        }}
      >
        <p css={tw`max-w-xl mx-auto px-6 text-center mb-4 text-xl font-light`}>
          {submitStatus === "success"
            ? `${_(
                "Thank you for signing up for our email offers! You're now in the loop for exclusive offers and deals on Genuine Toyota Parts & Accessories from Toyota Parts Center. Stay tuned for exciting updates and promotions!"
              )}`
            : _(`An unexpected error has occurred. Please try again.`)}
        </p>
      </FormPage>
    )
  }
  return (
    <FormPage
      active={active}
      nextButtonType={isValid ? "submit" : "button"}
      prevOnClick={() => {
        dispatch(setActiveView("MethodOfContactView"))
        tealiumNavClick(
          "Review & Submit Your Request",
          "Back",
          trackTealEvent,
          contactDealerData
        )
      }}
      nextOnClick={e => handleSubmit(e)}
      submitting={submitStatus === "submitting"}
      heading={_("Review & Submit Your Request")}
      nextButtonText={_("Submit")}
      showFormHeading={false}
    >
      <p
        css={[
          tw`mt-3 text-sm normal-case text-center max-w-[22rem] break-words`,
          tw`md:(max-w-[30rem])`,
        ]}
      >
        {_("You told us your name is")} {firstName} {lastName}
        {", "}
        {_("and you want to be contacted via email at")} {email}
      </p>
      <div css={tw`mt-6 w-full max-w-[30rem] relative text-center md:mt-8`}>
        <ReCAPTCHA
          sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
          ref={recaptchaRef}
          onChange={updateRecaptcha}
          css={tw`inline-block`}
        />
        {/* reCaptcha require validation popover */}
        <ValidationPopover
          showRequiredValidatioMsg={showValidatioMessage}
          message={_("Please confirm that you are not a robot.")}
          css={tw`bottom-[-2.75rem] md:(bottom-[-2.75rem])`}
        />
      </div>
    </FormPage>
  )
}

export default SummaryView
