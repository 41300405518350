import { graphql, Link, PageProps } from "gatsby"
import React, { useContext, useEffect, useRef } from "react"
import tw from "twin.macro"
import { Layout } from "../components/atoms/Layout"
import Benefits from "../components/molecules/Benefits/Benefits"
import GenuineParts from "../components/organisms/Parts/GenuineParts"
import PartsCenter from "../components/organisms/Parts/PartsCenter"
import Parts from "../components/organisms/Parts/Parts"
import Coupons from "../components/molecules/Coupons/Coupons"
import {
  Brochure,
  ImageBlocks,
  ToyotaCare,
} from "../components/organisms/Series"
import { SEO } from "../components/atoms/SEO"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import { DealersNearYou } from "../components/organisms/DealersNearYou"
import { Find } from "../components/molecules/Find"
import { ButtonLink } from "../components/atoms/Button"
import useDealers from "../hooks/useDealers"
import { LocationContext } from "../contexts/Location"
import useTealiumNoZip from "../hooks/Tealium/useTealiumNoZip"
import { LanguageContext } from "../contexts/Language"
import { CTA } from "../global"
import { getCTALinkValue } from "../helpers"
import VideoSection from "../components/molecules/VideoSection/Video"
import { RetentionSalesEventHero } from "../components/molecules/RetentionSalesEventHero"
import PartInfo from "../components/organisms/Part/Info"
import PartHighlights from "../components/organisms/Part/Highlights"
import PartAndServiceHero from "../components/organisms/PartAndServiceHero/PartAndServiceHero"
import CtaBox from "../components/molecules/Heros/CtaBox"
import TPCOEmailOptInSection from "../components/organisms/Parts/TPCOEmailOptInSection"
import TPCOForm from "../components/molecules/TPCOForm/TPCOForm"
import {
  TPCOEmailOptInContext,
  TPCOEmailOptInProvider,
} from "../contexts/TPCOEmailOptIn/context"
import {
  closeTPCOEmailOptInModal,
  openTPCOEmailOptInModal,
} from "../contexts/TPCOEmailOptIn/actions"
import TabbedImageModule from "../components/molecules/TabbedImageModule/TabbedImageModule"

const PartsPage: React.FC<PageProps> = ({ data, location }: any) => {
  const { page } = data
  //Tealium

  const { handleTealView } = useTealiumView()
  const [{ dealers }] = useContext(LocationContext)
  const [{ isModalOpen }, dispatch] = useContext(TPCOEmailOptInContext)
  const pageload = useRef(true)
  const { getDealerInfo } = useDealers()
  const [isOutOfArea] = useTealiumNoZip()

  useEffect(() => {
    const dealerInfo = getDealerInfo()
    /* pageload.current = Fire only on inital page load, and not every time dealers is updated */

    // "If no zip is set because visitor is from out of area"
    if (isOutOfArea && pageload.current) {
      pageload.current = false
      handleTealView({
        page_name: "parts",
        tealium_event: "parts_service_parts",
        page_type: "parts service",
        search_results: 0,
        dealer_code: "NOT SET",
        dealer_name: "NOT SET",
      })
    }

    if (pageload.current && dealers) {
      pageload.current = false
      handleTealView({
        page_name: "parts",
        tealium_event: "parts_service_parts",
        page_type: "parts service",
        search_results: dealers?.length,
        dealer_code: dealerInfo.dealerCodes,
        dealer_name: dealerInfo.dealerNames,
      })
    }
  }, [dealers, isOutOfArea])

  return (
    <TPCOEmailOptInProvider>
      <Layout tealium={{ label: "partsPage" }}>
        <SEO
          url={location.href}
          title={page.title}
          keywords={page.seo?.keywords}
          schema={page.seo?.schema}
          description={page.seo?.description}
          canonicalPath={page.seo?.canonicalPath}
        />
        <SanitySections data={page} />
        <TPCOForm
          modalIsOpen={isModalOpen}
          setModalIsOpen={open => {
            if (open) {
              dispatch(openTPCOEmailOptInModal())
            } else {
              dispatch(closeTPCOEmailOptInModal())
            }
          }}
        />
      </Layout>
    </TPCOEmailOptInProvider>
  )
}

const SanitySections = ({ data }: any): JSX.Element => {
  const retentionSalesEventData = data?.content?.find(
    (section: any) => section._type === "salesEventHeroPageBuilder"
  )?.salesEvent
  const retentionSalesEventHeroData = retentionSalesEventData?.salesEventActive
    ? retentionSalesEventData?.hero
    : null
  return (
    <>
      {data?.content?.map((section: any) => {
        switch (section?._type) {
          case "partsCenterHero": {
            return retentionSalesEventHeroData ? (
              <RetentionSalesEventHero {...retentionSalesEventHeroData} />
            ) : (
              <GenuineParts {...section} />
            )
          }
          case "partsAndServiceHero":
            if (retentionSalesEventHeroData) {
              return (
                <RetentionSalesEventHero {...retentionSalesEventHeroData} />
              )
            }

            return <PartAndServiceHero {...section} />
          case "partsCenterFeatured":
            return <Parts {...section} />
          case "dealersNearYouSection":
            const { _ } = useContext(LanguageContext)

            return (
              <DealersNearYou
                {...section}
                type="default"
                ctaAnalyticsId={"shop now"}
              />
            )
          case "videoSection":
            return <VideoSection {...section} />
          case "salesEventImageWithTextPageBuilder":
            if (!section?.salesEvent?.salesEventActive) return null
            return (
              <ToyotaCare
                {...section?.salesEvent?.imageWithText}
                imagePosition={section.imagePosition}
              />
            )
          case "partInfoSection":
            return (
              <>
                <PartInfo {...section} />
                <PartHighlights {...section} />
              </>
            )
          case "imageBlocks":
            return <ImageBlocks {...section} />
          case "tabbedImageModule":
            return <TabbedImageModule {...section} />
          case "imageWithText":
            return <ToyotaCare {...section} imageWithTextcta={section.cta} />
          case "brochure":
            return <Brochure {...section} />
          case "tpcoEmailOptIn":
            return <TPCOEmailOptInSection {...section} />
        }
      })}
    </>
  )
}
export const query = graphql`
  query PartsPageQuery($id: String) {
    page: sanityPageParts(id: { eq: $id }) {
      id
      language
      title
      seo {
        keywords
        schema
        description
        canonicalPath
      }
      content {
        ...VideoSectionData
        ...DealersNearYouData
        ...PartsCenterHero
        ...PartInfoData
        ...PartsByCategory
        ...PartsImageBlocksData
        ...ImageWithTextData
        ...RetentionSalesEventImageWithTextData
        ...RetentionSalesEventHeroData
        ...PartsAndServiceHeroData
        ...TabbedImageModuleData
        ...CallToActionBoxData
        ...BrochureData
        ...TpcoEmailOptInData
      }
      disclaimers {
        code
        disclaimer
      }
    }
  }
`

export default PartsPage
