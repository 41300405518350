import React, { useReducer, createContext } from "react"
import tpcoEmailOptInReducer from "./reducer"
import { TPCOEmailOptInContextState } from "./tpcoEmailOptIn"

export const defaultState: TPCOEmailOptInContextState = {
  isModalOpen: false,
  activeView: "NameView",
  fullName: "",
  firstName: "",
  lastName: "",
  email: "",
  gRecaptchaResponse: null,
  previousView: null,
}

export const TPCOEmailOptInContext = createContext<
  [TPCOEmailOptInContextState, React.Dispatch<any>]
>([defaultState, () => {}])

export const TPCOEmailOptInProvider = ({ children }) => {
  const [tpcoEmailOptInState, dispatch] = useReducer(
    tpcoEmailOptInReducer,
    defaultState
  )

  return (
    <TPCOEmailOptInContext.Provider value={[tpcoEmailOptInState, dispatch]}>
      {children}
    </TPCOEmailOptInContext.Provider>
  )
}
