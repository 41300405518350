import { FormView } from "./tpcoEmailOptIn"

export enum ACTION_TYPES {
  SET_MODAL_OPEN,
  SET_ACTIVE_VIEW,
  SET_PREVIOUS_VIEW,
  UPDATE_FIELD,
  CLEAR_STATE,
}

export const openTPCOEmailOptInModal = () => {
  return {
    type: ACTION_TYPES.SET_MODAL_OPEN,
    payload: true,
  }
}

export const closeTPCOEmailOptInModal = () => {
  return {
    type: ACTION_TYPES.SET_MODAL_OPEN,
    payload: false,
  }
}

export const setActiveView = (activeView: FormView) => {
  return {
    type: ACTION_TYPES.SET_ACTIVE_VIEW,
    payload: activeView,
  }
}

export const updateField = (key: string, value: any) => ({
  type: ACTION_TYPES.UPDATE_FIELD,
  payload: {
    key: key,
    value: value,
  },
})

export const clearState = () => {
  return {
    type: ACTION_TYPES.CLEAR_STATE,
  }
}

export const setPreviousView = (previousView: FormView) => {
  return {
    type: ACTION_TYPES.SET_PREVIOUS_VIEW,
    payload: previousView,
  }
}
