import React, { useContext } from "react"
import { TPCOEmailOptInSectionProps } from "./Parts.d"
import { Image } from "../../atoms/Image"
import Logo from "../../atoms/Logo"
import tw from "twin.macro"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { parseDisclaimerBlocks } from "../../../helpers"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { LanguageContext } from "../../../contexts/Language"
import { Button } from "../../atoms/Button"
import { openTPCOEmailOptInModal } from "../../../contexts/TPCOEmailOptIn/actions"
import { TPCOEmailOptInContext } from "../../../contexts/TPCOEmailOptIn/context"
import { useTealiumContext } from "../../../contexts/Tealium"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"

const TPCOEmailOptInSection: React.FC<TPCOEmailOptInSectionProps> = ({
  backgroundImage,
  headerLogo,
  headerText,
  description,
  ctaLabel,
}) => {
  const [{}, dispatch] = useContext(DisclaimersContext)
  const { _ } = useContext(LanguageContext)
  const [state, dispatchEmailOptIn] = useContext(TPCOEmailOptInContext)
  const HeadingLogo = headerLogo && Logo[headerLogo]

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { tealPageData } = useTealiumContext()

  return (
    <section css={[tw`flex p-8`, tw`md:(p-0)`]}>
      <div css={[tw`hidden`, tw`md:(block relative w-1/2)`, tw`xl:(w-1/3)`]}>
        <Image
          imageData={backgroundImage.image}
          css={tw`h-full w-full object-cover object-left min-h-[375px]`}
        />
        {/* Background Image Gradient */}
        <div
          css={[
            tw`absolute h-full right-0 top-0 w-1/2`,
            "background: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(255,255,255) 65%);",
          ]}
        />
      </div>
      <div
        css={[
          tw`flex justify-center flex-col relative`,
          tw`md:(w-1/2)`,
          tw`lg:(-ml-8 py-12 pr-12) xl:(w-2/3)`,
        ]}
      >
        <h2
          css={[
            tw`text-4xl font-semibold flex items-center gap-4 mb-8`,
            tw`lg:(mb-4 text-4xl)`,
          ]}
        >
          {headerLogo && (
            <HeadingLogo
              css={[tw`inline-block w-20`, tw`md:(w-16)`]}
              aria-hidden={true}
              alt=""
              showText={false}
            />
          )}
          {headerText && (
            <span>
              {parseDisclaimerBlocks(headerText, selection =>
                dispatch(toggleDisclaimersModal(selection))
              )}
            </span>
          )}
        </h2>
        <div css={[tw`flex gap-8 flex-col`, tw`xl:(flex-row gap-8)`]}>
          {description && (
            <p css={[tw`md:(pr-8)`, tw`xl:(w-3/4)`, tw`2xl:(w-4/5)`]}>
              {parseDisclaimerBlocks(description, selection =>
                dispatch(toggleDisclaimersModal(selection))
              )}
            </p>
          )}
          <div>
            <Button
              primary
              onClick={() => {
                dispatchEmailOptIn(openTPCOEmailOptInModal())
                trackTealEvent({
                  tealium_event: "contact_dealer_rest",
                  coupon_module_text: "Sign Me Up",
                  page_type: tealPageData.page_type,
                })
              }}
              analytics-id=":sign me up:email:"
            >
              {ctaLabel || _("SIGN ME UP!")}
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TPCOEmailOptInSection
